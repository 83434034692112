import React from 'react';

export default function Testimonial({ data }){

    return (

        <div className="single-testimonial d-flex flex-column">
            <div className="testimonial-text">
                <p className="text"><em>{ data.quote }</em></p>
            </div>
            <div className="testimonial-author d-sm-flex justify-content-between pt-4">
                <div className="author-info d-flex align-items-center">
                    <div className="author-image">
                        <img src={ '/img/' + data.img } alt="author" />
                    </div>
                    <div className="author-name media-body">
                        <h5 className="name">{ data.author}</h5>
                        <span className="sub-title">{ data.firm }</span>
                    </div>
                </div>
            </div>
        </div> 

    );


}

