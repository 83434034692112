import React from "react";
import { graphql } from "gatsby";
import Footer from "../components/Footer";
import SEO from "../components/seo";
import Companies from "../components/Companies";
import Testimonial from "../components/Testimonial";
import { Helmet } from "react-helmet";
import Cta from "../components/Cta";
import Header from "../components/Header";
import PageWrapper from "../PageWrapper";
// import heroSvg from '../img/undraw/undraw_onboarding_o8mv--lexiqa.svg';
import heroSvg from '../img/undraw/undraw_sync_files_xb3r.svg';
// import about from "../img/about.jpg"
import PAGEDATA from "../../content/index.json"
import TESTIMONIALS from "../../content/testimonials.json"
import Link from 'gatsby-link';

function Hero({ bgImage }){

  return (
      <>
          <div className="header-hero header-hero--index bg_cover d-lg-flex align-items-center">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8 text-center">
                            <div className="header-hero-content">
                                <h1 className="hero-title">
                                  AUTOMATE YOUR<br/>
                                  <b>TRANSLATION QUALITY</b>
                                </h1>
                                <h2 className="hero-subtitle mt-3">
                                    <span>API-BASED</span> <span>LOCALE-SPECIFIC</span>
                                    <span>QA AS YOU TYPE</span>
                                    <span>195 LOCALES</span>
                                </h2>
                                <p>
                                  <Link className="mt-5 px-4 btn-cta btn btn-primary btn-lg" to="demo-request">
                                  Request a demo
                                  </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-hero-image d-flex align-items-center">
                    <img src={bgImage} alt="Hero" />
                </div>
            </div>
      </>

  );

}

const Index = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <PageWrapper>
      <Helmet>
        {/* Slick jQuery Plugin */}
        <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css"/>
      </Helmet>
      <Header component={<Hero bgImage={heroSvg}/>} title={siteTitle} />
      <SEO title="Automate your translation quality - lexiQA" />
      <SEO description="Save time and money, and improve your localization workflow with locale-specific QA checks" />

      {/* <!--====== LIST OF TRUSTED BY START ======--> */}

      <div className="pb-50 pt-50">
        <Companies data={PAGEDATA.trustedby} title="Trusted By" cols={4} />
      </div>
      {/* <!--====== LIST OF TRUSTED BY END ======--> */}

      {/*====== TESTIMONIAL PART START ======*/}
      <section id="testimonials" className="testimonial-area bg-light pt-70 pb-120">

        <div className="container">
          <div className="row justify-content-between mr-0 ml-0">

            {TESTIMONIALS.map( testimonial =>{
                return (
                  <div key={ testimonial.author } className="col-lg-6 d-flex">
                    <div className="testimonial-right-content mt-50">
      
                      <div className="quota">
                        <i className="flaticon-quote-left" />
                      </div>
      
                      <div className="testimonial-content-wrapper testimonial-active">
                      <Testimonial data={ testimonial } />
                      </div> 
      
                    </div> {/* testimonial right content */}
                    </div>
                );
            })}



          </div> {/* row */}
        </div> 

      </section>

      {/*====== TESTIMONIAL PART ENDS ======*/}

      {/* <!--====== LIST OF INTEGRATED WITH START ======--> */}

      <div className="pt-100 pb-100">
        <Companies data={PAGEDATA.integratedwith} title="Integrated With" cols={4} />
        <p className="text text-center mt-5">
          Visit <a className=" lxq-link" href="https://testserver.lexiqa.net/docs/lexiqa/integrations/" target="_blank" rel="noopener noreferrer">our Documentation</a> to see how lexiQA integrates with these platforms.
        </p>
      </div>

      {/* <!--====== LIST OF INTEGRATED WITH END ======--> */}

      {/* <!--====== INTEGRATED WITH PLATFORM START ======--> */}

      <section className="about-area bg-light pt-100 pb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="about-content">
                <h3 className="title mb-30 text-center">
                  Integrate with any platform
                </h3>
                <p className="text text-center">
                  lexiQA exposes its data via an Application Programming Interface (API),
                  so developers can interact in a programmatic way with the lexiQA
                  application. Our API calls make use of the most common HTTP methods and are REST-compliant.
                </p>
              </div>
              {/* <!-- about content --> */}
            </div>
            {/* <div className="col-lg-6">
              <div className="about-image">
                <img src={about} alt="about" />
              </div> */}
              {/* <!-- about image --> */}
            {/* </div> */}
          </div>
          {/*<!-- row --> */}
        </div>
        {/* <!-- container --> */}
      </section>

      {/* <!--====== INTEGRATED WITH PLATFORM END ======--> */}

      {/* <!--====== CALL TO ACTION START======--> */}
      <section className="call-to-action-area bg-white pt-100 pb-100">
        <div className="container"> 
              <Cta data={ PAGEDATA.cta }/>
        </div>
      </section>
      {/* <!--====== CALL TO ACTION END======--> */}

      <Footer/>

    </PageWrapper>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
