import React from "react"
import "./Companies.scss"

function Companies(props) {
  return (
    <div className="companies-component container">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8">
          <div className="about-title text-center">
            <h3 className="title">{props.title}</h3>
          </div>
          {/* <!-- section title --> */}
        </div>
      </div>
      <div className="row">
        {props.data.map(client => {
          return (
            <div className={"col-md-" + props.cols + " size"} key={client.img}>
              <div className="d-flex m-auto justify-content-center image_div">
                <a href={client.url} target="_blank" rel="noopener noreferrer"><img src={"/img/" + client.img} alt={client.alt}/></a>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Companies
