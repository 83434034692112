import React from "react"
import Link from "gatsby-link"
import "./Cta.scss"

function Cta(props) {
  return (
    <div className="cta-component row justify-content-center">
      {props.data.map( (cta, idx, list) => {
        const isLeftColumn = idx === 0;
        const isRightColumn = idx === list.length - 1; 
        return (
          <div className={ isLeftColumn ? "pl-0 col-md-4" : isRightColumn ? "pr-0 col-md-4" : "col-md-4" } key={cta.title}>
            <div className="call-action">
              <div className="section-title text-center section-title-white">
                <h5 className="title mb-3">
                  <span>{cta.title}</span>
                </h5>
                {/* <h6 className="sub-title mb-5">{cta.subtitle}</h6> */}
                <h6 className="sub-title d-flex flex-column justify-content-center mb-5" dangerouslySetInnerHTML={{ __html: cta.subtitle}}></h6>
              </div>

              <div className="text-center mb-5">

                {/* Render Internal Link */}
                {cta.page && (
                  <Link
                    className="main-btn btn-primary d-inline-flex justify-content-center align-items-center"
                    to={cta.page}
                  >
                    {cta.btn}
                  </Link>
                )}

                {/* Render External Links */}
                { cta['external-link'] && (
                  <a
                    className="main-btn btn-primary d-inline-flex justify-content-center align-items-center"
                    href={cta['external-link']}
                  >
                    {cta.btn}
                  </a>
                )}

              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Cta
